<bodyanalytics-header></bodyanalytics-header>
<main>
  <section class="">
    <div class="container" *ngIf="auth.isLoading$ | async; else loaded">
      <div class="row g-4 g-lg-5">
        <div class="col-12 text-center">
          <bodyanalytics-loading-spinner></bodyanalytics-loading-spinner>
        </div>
      </div>
    </div>
    <ng-template #loaded>
      <div class="row g-4 g-lg-5">
        <div class="col-12">
          <!-- <div class="col-lg-8 mx-auto"> -->
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-template>
  </section>
</main>
<bodyanalytics-footer></bodyanalytics-footer>
