import { Route } from '@angular/router';
import { LoginComponent } from '@bodyanalytics/authentication-ui';
import {  ServerErrorComponent } from '@bodyanalytics/ui-core';



export const appRoutes: Route[] = [
  {
    path: 'calendar',
    loadChildren: () => import('@bodyanalytics/calendar-ui').then(mod => mod.CalendarUiModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ServerErrorComponent },

  {
    path: 'booking',
    loadChildren: () => import('@bodyanalytics/booking-ui').then(mod => mod.BookingUiModule),
  },
  // { path: '**', component: PageNotFoundComponent },
  { path: '', pathMatch: 'full', redirectTo: 'calendar' },
  { path: '**', redirectTo: 'calendar' },
];
