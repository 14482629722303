import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, provideRouter, withComponentInputBinding } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { UiCoreModule } from '@bodyanalytics/ui-core';
import { HttpClientModule } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { StoreModule } from '@ngrx/store';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ENV } from '@bodyanalytics/app-config';
import { provideAuth0, authHttpInterceptorFn } from '@auth0/auth0-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { RegistrationUiModule } from '@bodyanalytics/registration-ui';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    UiCoreModule,
    HttpClientModule,
    RegistrationUiModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    NgxStripeModule.forRoot(
      environment.stripePKey
    ),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    // EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      // connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
   providers: [
    { provide: ENV, useValue: environment },
    provideAnimations(),
    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          `${environment.api}/register`,
          `${environment.api}/checkout-api/create-payment-intent`,
          `${environment.api}/manage-booking-api/cancel-booking`,
          `${environment.api}/manage-booking-api/process-booking`,
          `${environment.api}/manage-booking-api/process-booking-credit`,
          `${environment.api}/manage-booking-api/details/*`,
          `${environment.api}/manage-booking-api/reschedule-booking`,
          `${environment.api}/manage-booking-api/search/*`,
          `${environment.api}/manage-orders-api/details/*`,
         ],
      },
    }),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideRouter(appRoutes, withComponentInputBinding()),

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
